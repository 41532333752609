@import '../../App.scss';

.main {
  @include grid;
  align-items: center;

  &__button {
    background-color: $primary;
    color: $white;
    @include buttons;
    position: relative;
    z-index: 6;
    padding: 10px 80px;
    font-size: 20px;
    margin-block: 20px;
    
    @include on-tablet {
      float: inline-end;
      padding: 20px 70px;
      font-size: 24px;
    }
  }

  &__text {
    margin-bottom: 30px;
  }

  &__title {
    grid-column: 1 / -1;
    @include h1;
    color: $primary;
    padding-bottom: 16px;
  }
  
  &__textblock {
    grid-column: 1 / -1;
    @include body-text;
    color: $secondary;
    margin-top: 60px;
    
    @include on-tablet {
      margin-top: 0px;
      grid-column: 1 / 7;
    }

    @include on-desktop {
      grid-column: 1 / 11;
    }
  }
  
  &__img {
    grid-column: 1 / -1;
    width: 100%;
    margin: 40px auto 0;
    display: none;

    @include on-tablet {
      display: block;
    }

    img {
      width: 500px;
    }

    @include on-tablet {
      grid-column: 7 / -1;
    }

    @include on-desktop {
      grid-column: 13 / -1;
    }
  }

  &__button {
    grid-column: 1 / -1;
    margin-block: 16px;

    &--text {
      @include body-text;
      color: $primary;
    }

    &--button {
      padding: 5px 16px;
      background-color: $primary;
      color: $white;
      @include buttons;
    }
  }

}
.about {
  position: relative;
  margin-bottom: 60px !important;

  &__example-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    
    @include on-tablet {
      align-items: normal;
      gap: 100px;
      flex-direction: row;
    }
  }

  &__nft-example {
    width: 450px;

    @include on-tablet {
      width: 450px;
    }

    @include on-desktop {
      width: 450px;
    }
  }

  &__title {
    @include h1;
    color: $primary;
    margin: 40px 0 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $elements;
  }

  &__text {
    @include body-text;
    color: $secondary;
  }

  &__flag {
    display: none;
    transition: none;
    
    @include on-desktop {
      display: block;
      width: 360px;
      height: 360px;
      position: absolute;
      top: 15px;
      right: -150px;
    }
  }

  &__drone1 {
    display: none;
    transition: none;

    @include on-desktop {
      display: block;
      height: 360px;
      position: absolute;
      top: -250px;
      left: -320px;
    }
  }

  &__drone2 {
    display: none;
    transition: none;
    
    @include on-desktop {
      display: block;
      height: 360px;
      position: absolute;
      right: -250px;
      top: -180px;
    }
  }

  &__buy {
    grid-column: 1 / -1;
    margin-block: 16px;

    &--text {
      @include body-text;
      color: $primary;
    }

    &--button {
      margin-left: 16px;
      padding: 5px 16px;
      background-color: $primary;
      color: $white;
      @include buttons;
    }
  }
}

.reason {
  @include grid;
  gap: 16px;

  &__title {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    @include h1;
    color: $primary;
    padding-bottom: 16px;
    border-bottom: 1px solid $elements;

  }

  &__number {
    @include h1;
    font-size: 60px;
    color: $primary;
  }

  &__aside {
    &--title{
      @include h4;
      color: $primary;
    }
    &--text {
      @include body-text;
      color: $secondary;
    
    }
  }
  

  &__block {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    gap: 16px;

    &--1 {
      grid-row: 2 / 3;

      @include on-tablet {
        grid-column: 1 / 7;
        grid-row: 2 / 3;
      }

      @include on-desktop {
        grid-column: 1 / 13;
        grid-row: 2 / 3;
      }

      .reason__number {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    &--2 {
      grid-row: 3 / 4;

      @include on-tablet {
        grid-column: 1 / 7;
        grid-row: 3 / 4;
      }

      @include on-desktop {
        grid-column: 1 / 13;
        grid-row: 3 / 4;
      }
    }
    &--3 {
      grid-row: 4 / 5;

      @include on-tablet {
        grid-column: 7 / -1;
        grid-row: 2 / 3;
      }

      @include on-desktop {
        grid-column: 13 / -1;
        grid-row: 2 / 3;
      }
    }
    &--4 {
      grid-row: 5 / 6;

      @include on-tablet {
        grid-column: 7 / -1;
        grid-row: 3 / 4;
      }

      @include on-desktop {
        grid-column: 13 / -1;
        grid-row: 3 / 4;
      }
    }
  }
}
