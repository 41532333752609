@import '../../App.scss';

.error-message {
  position: relative;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  animation: fadeInOut 2s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tokens {
  &__message {
    @include body-text;
    color: $secondary;
    position: absolute;
    top: 85px;
  }

  &__title {
    @include h1;
    color: $primary;
    margin: 40px 0 16px;
    padding-bottom: 16px;
  }
  
  &__box {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $elements;
    margin-bottom: 25px;
    position: relative;
  }

  &__all {
    @include grid;
    grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
    column-gap: 16px;
    row-gap: 40px;
  }
}
